import { useEffect, useState } from "react";

import "./DiaryTimeCard.css";
import ActivityType from "./diaryTimeCards/ActivityType";
import Images from "./diaryTimeCards/Images";
import Productivity from "./diaryTimeCards/Productivity";
import DefaultWebcam from "../../../images/noCamshot.jpg"
import DefaultScreenshot from "../../../images/noScreenshot.jpg"
import TimeCardModal from "./TimeCardModal";
import Backdrop from "../../ui/Backdrop";
import { fetchImageFromAPI } from "../../api/apiCalls";
import { baseURL } from "../../api/env";
import LoadingSpinner from "../UiElements/LoadingSpinner";

function DiaryTimeCard(props) {
  const [timeCardModalIsOpen, setTimeCardModalIsOpen] = useState(false);
  const [timeCardIsSelected, setTimeCardIsSelected] = useState(false);
  const [screenshot, setScreenshot] = useState();
  const [webcam, setWebcam] = useState();
  const status = ['Productive', 'Idle', 'Overtime', 'Manual Time', 'Marked', 'OT Discard'];
  const [loading, setLoading] = useState(true)

  function openCardDetails() {
    setTimeCardModalIsOpen(true);
  }

  function closeModalHandler() {
    setTimeCardModalIsOpen(false);
  }

  function ToggleTimeCard(e) {
    setTimeCardIsSelected(!timeCardIsSelected);
    props.handleSelection(e.target.checked, e.target.id)
  }

  const fetchImages = async(props) => {
    let scshot = props.screensrc === 'default' || props.screensrc === undefined ? DefaultScreenshot: await fetchImageFromAPI(`${baseURL}TrackingData/GetScreenshots?ImagePath=screenshots/` + props.screensrc + ".jpg")
    let wcam = props.camsrc === 'default' || props.camsrc === undefined ? DefaultWebcam: await fetchImageFromAPI(`${baseURL}TrackingData/GetWebcam?ImagePath=webcam/`+ props.camsrc + ".jpg")
    setScreenshot(scshot)
    setWebcam(wcam)
  }

  useEffect(() => {
    const fetchData = async() => {
      try {
        await fetchImages(props)
        setLoading(false)
      } catch (error) {
        console.error("ERROR: ", error)
      }
    }
    
    fetchData()
  }, [props])

  let productivity = status[props.status];

  return (
    <>
      {!loading ? (
        
        <div className="diaryTimeCard">
          <div className="diaryTimeCard-Actions">
            <input type="checkbox" className="cards-checkboxes" onChange={ToggleTimeCard} id={props.id} />
            
            <ActivityType
              activityTime={props.activitytime}
              activity={status[props.status]}
              />
          </div>
          <Productivity
            project={props.workDescription}
            mouse={props.productivityMouse}
            keys={props.productivityKeys}
            productivityType={productivity}
          />
          <div className="diary-shots" onClick={openCardDetails}>
            <Images imageSRC={screenshot} />
            <Images imageSRC={webcam} />
          </div>

          {timeCardModalIsOpen && (
            <TimeCardModal
              activityTime={props.activitytime}
              project={props.workDescription}
              mouse={props.productivityMouse}
              keys={props.productivityKeys}
              productivityType={productivity}
              screensrc={props.screensrc}
              camsrc={props.camsrc}
            />
          )}
          {timeCardModalIsOpen && <Backdrop onCancel={closeModalHandler} />}
        </div>
      ) : (
        <LoadingSpinner/>
      )}
    </>
  );
}

export default DiaryTimeCard;
