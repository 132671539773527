import React, { Component } from 'react';
import './Autocomplete.css';

class Autocomplete extends Component {
  static defaultProps = {
    suggestions: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      // The active selection's index
      activeSuggestion: 0,
      // The suggestions that match the user's input
      filteredSuggestions: [],
      // Whether or not the suggestion list is shown
      showSuggestions: false,
      // What the user has entered
      userInput: '',
    };
  }

  registerAgent = (suggestion) => {
    this.props.addMember(suggestion);
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: suggestion.name,
    });
  }

  onChange = (e) => {
    const { suggestions } = this.props;
    const userInput = e.currentTarget.value;
    this.props.searchString(userInput);
    // Filter our suggestions that don't contain the user's input
    const filteredSuggestions = suggestions;

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value,
    });
  };

  onClick = (e) => {
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText,
    });
  };

  

  onKeyDown = (e) => {
    const { activeSuggestion, filteredSuggestions } = this.state;
    
    // User pressed the enter key
    if (e.keyCode == 13) {
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: filteredSuggestions[activeSuggestion],
      });
    }
    // User pressed the up arrow
    else if (e.keyCode == 38) {
      if (activeSuggestion == 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow
    else if (e.keyCode == 40) {
      if (activeSuggestion - 1 == filteredSuggestions.length) {
        return;

      }
     

      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  render() {
    const {
      onChange,
      onKeyDown,
      registerAgent,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput,
      },
    } = this;

    let suggestionsListComponent;
    

    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul className='suggestions'>
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index == activeSuggestion) {
                className = 'suggestion-active';
              }

              return (
                <li className={className} key={suggestion.id} onClick={() => registerAgent(suggestion)}>
                  {suggestion.name}
                </li>
              );
            })}
          </ul>
        );
      } else {
        suggestionsListComponent = (
          <div className='no-suggestions'>
            <em>No Team Members!</em>
          </div>
        );
      }
    }

    return (
      <>
        <input
          type='text'
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={userInput}
          id='autocomplete-input'
          placeholder={this.props.placeholderText}
        />
        {suggestionsListComponent}
      </>
    );
  }
}

export default Autocomplete;
