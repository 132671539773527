import DataTableBase from "../../ui/DataTableBase";
import Autocomplete from "../../ui/Autocomplete";
import { get, post } from "../../api/apiCalls";
import { ReactSession } from 'react-client-session';
import { useEffect, useState } from "react";
import { MdAddCircleOutline } from "react-icons/md";
import './ProjectTeamModal.css'

export function ProjectTeamModal(props){
  console.log("TEAM PAGE PROPS: ", props)

  let [employeeNames, setEmployeeNames] = useState([])
  let user = ReactSession.get("User");

  const addMember = async() => {
    if(chosenAgent!=null)
    {
      alert(chosenAgent.name + " added to " + props.teamMembers.project);
      let keys = { agentUsername:chosenAgent.username , managerUsername: user.username , projectID : props.teamMembers.id };
      await post("Projects/AssignAgent",keys);

      props.updateTeamMembers(props.teamMembers)
    }
  }

  const searchString =  async(search) => {
    let searchResults = [];
    if(search.length >= 3){
      let projectResult = await get("Users/SearchUsers?searchString="+search);
      
      if(projectResult !=null){
        for (const item of projectResult) {
          searchResults.push({
            name : item.firstName+ " " + item.lastname,
            id : item.id,
            username : item.username
          })
        }
        setEmployeeNames(searchResults);
      }
    }
  }
  let chosenAgent = ''
  const  setNewMember = async(suggestion) => {
    chosenAgent = suggestion;
  }

  const removeMember = async(data) => {
    
    let keys = { agentUsername:data.username , managerUsername: user.username , projectID : props.teamMembers.id };
    await post("Projects/RemoveAgent",keys);
    props.updateTeamMembers(props.teamMembers)
  }

  const columns = [
    {
      id: 'name',
      name: 'Name',
      selector: (row) => row.agentname,
    },
    {
      id: 'removeAgent',
      name: 'Remove Agent',
      selector: (row) => row.removeAgent,
      cell: (row) => {
        return (
          <button className='team-members-remove-agent-btn' onClick={() => removeMember(row)}>
            Delete Member
          </button>
        );
      },
    },
  ];

  function closeProjectTeamMembersModal(){
    props.onClose()
  }
  
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
      props.onClose(); 
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
        window.removeEventListener('keydown', handleKeyDown);
    };
  }, [props.onClose])

  function closeModal(){
    props.onClose()
  }

  return(
    <div className='team-members-modal-page' onClick={(e) => {
        if (e.target === e.currentTarget) {
          closeModal();
        }
      }}
    >
      <div className='team-members-modal-box'>
        <div className='project-team-members-headers'>Project Team Members</div>
        <div id='section-project-members'>
          <div className='add-team-member-to-project'>
            <Autocomplete
              suggestions={employeeNames}
              placeholderText='Add Member by User Name'
              searchString={searchString}
              addMember={setNewMember}

            />
            <MdAddCircleOutline 
              size={'1.5em'}
              cursor={'pointer'}
              onClick={addMember}
            />
          </div>
          <DataTableBase data={props.teamMembers.agents} columns={columns} fileName={"Project Members"}/>
        </div>
        <div className="team-members-modal-btn">
          <button onClick={closeProjectTeamMembersModal} className="team-members-close-modal-btn">Close</button>
        </div>
      </div>
    </div>
  )
}