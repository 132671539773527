import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useState } from "react"
import { get, put } from "../api/apiCalls";
import { AdminUserSettingsHeaderLink } from "../layout/header/AdminUserSettingsHeaderLink";
import { Box, Grid } from "@mui/material";
import { FaUpload, FaDownload, FaEdit } from "react-icons/fa";
import { baseURL } from '../api/env'
import { UploadResumeModal } from "./UploadResumeModal";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FloatLabel } from "primereact/floatlabel";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import axios from "axios";
import './AdminEmployeePage.css'
import { InputText } from "primereact/inputtext";

export function AdminEmployeePage(){
    let emptyEmployee = {
        name: '',
        email: '',
        location: '',
        phoneNumber: '',
        status: ''
    };

    const employeeStatus = [
        'Onboarded',
        'Offboarded',
        'On-Hold',
        'No Project'
    ]

    const[name, setName] = useState('')
    const[email, setEmail] = useState('')
    const[location, setLocation] = useState('')
    const[phone, setPhone] = useState('')

    const [skills, setSkills] = useState([]);
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [currentEmployeeSkills, setCurrentEmployeeSkills] = useState([]);

    const [languages, setLanguages] = useState([]);
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [currentEmployeeLanguages, setCurrentEmployeeLanguages] = useState([]);

    const [productTraining, setProductTraining] = useState([]);
    const [selectedProductTraining, setSelectedProductTraining] = useState([]);
    const [currentEmployeeProducts, setCurrentEmployeeProductTraining] = useState([]);

    const [selectedStatus, setSelectedStatus] = useState('');
    const [currentEmployeeStatus, setCurrentEmployeeStatus] = useState('');

    const [loading, setLoading] = useState(true);
    const [employees, setEmployees] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState({});
    const [uploadFileModal, setUploadFileModal] = useState(false);
    const [employee, setEmployee] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [addEmployeeDialog, setAddEmployeeDialog] = useState(false);
    const [updateEmployeeDialog, setUpdateEmployeeDialog] = useState(false);
    const [file, setFile] = useState('');
    const token = sessionStorage.getItem("token");

    const hasChanged = () => selectedProductTraining === currentEmployeeProducts && selectedLanguages === currentEmployeeLanguages && selectedSkills === currentEmployeeSkills && selectedStatus === currentEmployeeStatus;

    async function getEmployees(){
        const response = await get("Employee");
        setEmployees(response);
        setLoading(false)
    }

    function handleResumeSelection(event){
        setFile(event.target.files[0])
        
    }

    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button className="rounded" label="New" icon="pi pi-plus" severity="success" onClick={openNew} />
            </div>
        );
    };
    
    const openNew = () => {
        setEmployee(emptyEmployee);
        setSubmitted(false);
        setAddEmployeeDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setAddEmployeeDialog(false);
    };

    const openNewUpdateDialog = () => {
        setSubmitted(false);
        setUpdateEmployeeDialog(true);
    };

    const hideUpdateDialog = () => {
        setSubmitted(false);
        setUpdateEmployeeDialog(false);
    };

    const saveEmployee = async () => {
        const url = `${baseURL}Employee`;
        const formData = new FormData();
        formData.append('Name', employee.name);
        formData.append('Email', employee.email);
        formData.append('Location', employee.location);
        formData.append('ResumeID', file);
        formData.append('PhoneNumber', employee.phoneNumber);
        formData.append('Status', selectedStatus);
        selectedLanguages.forEach(item => formData.append('LanguageIds[]', item.id));
        selectedSkills.forEach(item => formData.append('SkillIds[]', item.id));
        selectedProductTraining.forEach(item => formData.append('ProductTrainingIds[]', item.id));

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
            },
        };
        axios.post(url, formData, config).then((response) => {
            alert(response.data)
            getEmployees()
        }).catch((error) => {
            alert(error.response.data)
        });

        setSubmitted(true);
        setAddEmployeeDialog(false);
        setEmployee(emptyEmployee);
        setSelectedStatus('');
        setSelectedSkills([])
        setSelectedLanguages([])
        setSelectedProductTraining([])
    };

    const addEmployeeDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
            <Button 
            label="Save" 
            icon="pi pi-check" 
            onClick={saveEmployee}
            disabled={emptyEmployee.name === '' && emptyEmployee.email === '' && emptyEmployee.location === '' && emptyEmployee.phoneNumber === '' && isEmpty(selectedSkills, selectedLanguages, selectedStatus, file)}/>
        </>
    );

    const updateEmployeeDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" outlined onClick={hideUpdateDialog} />
            <Button 
                label="Save" 
                icon="pi pi-check" 
                onClick={updateEmployee}
                disabled={hasChanged() || selectedLanguages.length <= 0 || selectedSkills.length <= 0}
            />
        </>
    );

    const downloadResume = (fileName) => {
        fetch(`${baseURL}Employee/FetchResumeFromS3/${fileName}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
        .then((response) => {
            if (response.ok) {
            return response.blob();
            }
            throw new Error('Error downloading file');
        })
        .then((blob) => {
            const link = document.createElement('a');
            const url = window.URL.createObjectURL(blob);
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
        .catch((error) => {
            console.error(error);
        });
    };

    const actionsBodyTemplate = (employee) => {
        return (
            <div className="employees-table-action-btns">
                <button title="Upload resume" onClick={() => handleOpenModal(employee)}><FaUpload /></button>
                <button onClick={() => downloadResume(employee.resume)} title="Download resume"><FaDownload /></button>
            </div>
        );
    };

    const editBodyTemplate = (employee) => {
        return (
            <div className="employees-table-edit-btns">
                <button title="Edit employee details" onClick={() => handleOpenEditEmployeeModal(employee)}><FaEdit /></button>
            </div>
        );
    };

    function handleOpenModal(employee){
        setSelectedEmployee(employee)
        setUploadFileModal(true)
    }
    
    function handleOpenEditEmployeeModal(employee){
        setSelectedEmployee(employee)
        let employeeSkills = employee.skills.split(',').map(lang => lang.trim());
        const currentValue = skills.filter(obj => 
            employeeSkills.map(lang => lang.toLowerCase()).includes(obj.skill.toLowerCase())
        );
        setCurrentEmployeeSkills(currentValue)
        setSelectedSkills(currentValue) 

        let employeeLanguages = employee.languages.split(',').map(lang => lang.trim());
        const currentLanguagesValue = languages.filter(obj => 
            employeeLanguages.map(lang => lang.toLowerCase()).includes(obj.language.toLowerCase())
        );
        setCurrentEmployeeLanguages(currentLanguagesValue)
        setSelectedLanguages(currentLanguagesValue) 

        let employeeProductTraining = employee.productTraining.split(',').map(lang => lang.trim());
        const currentProductsValue = productTraining.filter(obj => 
            employeeProductTraining.map(lang => lang.toLowerCase()).includes(obj.skill.toLowerCase())
        );
        setCurrentEmployeeProductTraining(currentProductsValue)
        setSelectedProductTraining(currentProductsValue) 
        setCurrentEmployeeStatus(employee.status)
        setSelectedStatus(employee.status)

        setName(employee.name)
        setEmail(employee.email)
        setLocation(employee.location)
        setPhone(employee.phoneNumber)

        openNewUpdateDialog()
    }


    function onCloseModal(){
        setUploadFileModal(false)
    }

    function handleEmail(event){
        setEmail(event.target.value)
    }

    function handelName(event){
        setName(event.target.value)
        
    }

    function handleLocation(event){
        setLocation(event.target.value)
        
    }

    function handlePhone(event){
        setPhone(event.target.value)
    }

    const footer = `In total there are ${employees ? employees.length : 0} employees.`;

    async function uploadResume(resume, employeeID){
        setLoading(true)
        const url = `${baseURL}Employee/UploadResumeToS3`;
        const formData = new FormData();
        formData.append('file', resume);
        formData.append('employeeID', employeeID);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
            },
        };
        axios.post(url, formData, config).then((response) => {
            setUploadFileModal(false)
            alert(response.data)
            getEmployees()
        }).catch((error) => {
            alert(error.response.data)
        });
    }

    async function updateEmployee(){
        let skills = []
        selectedSkills.forEach((item) => {
            skills.push(item.id)
        })

        let languages = []
        selectedLanguages.forEach((item) => {
            languages.push(item.id)
        })
        
        let products = []
        selectedProductTraining.forEach((item) => {
            products.push(item.id)
        })

        let keys = {
            ID: selectedEmployee.id,
            Name: name || selectedEmployee.name,
            Email: email || selectedEmployee.email,
            Location: location || selectedEmployee.location,
            PhoneNumber: phone || selectedEmployee.phoneNumber,
            Status: selectedStatus || selectedEmployee.status,
            Skills: skills,
            Languages: languages,
            ProductTraining: products,

        }
        let response = await put('Employee', keys)
        alert(response)

        hideUpdateDialog()
        getEmployees()
        setSubmitted(true)
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _employee = { ...employee };

        _employee[`${name}`] = val;
        setEmployee(_employee);
    };

    async function getSkills(){
        let response = await get('Employee/Skills')
        setSkills(response)

    }
    
    async function getLanguages(){
        let response = await get('Employee/Languages');
        setLanguages(response);

    }
    
    async function getProductTraining(){
        let response = await get('Employee/ProductTraining')
        setProductTraining(response)
        
    }

    function isEmpty(skills, languages, status, file) {

        const hasEmptyStatus = status === '';

        const hasEmptyFile = !file;

        const areListsEmpty = skills.length === 0 || languages.length === 0 || productTraining.length === 0;
    
        return hasEmptyStatus  || hasEmptyFile || areListsEmpty;
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getEmployees()
                await getSkills()
                await getLanguages()
                await getProductTraining()
            } catch (error) {
              console.error("Error fetching employees:", error);
            }
          };
      
          fetchData();
    }, []);
    return(
        <div className="settingsContainer"> 
            <AdminUserSettingsHeaderLink/>
            {
                <Box sx={{flexGrow: 1}} className="settingsBody">
                    <Grid container>
                        <Grid xs={10}>
                            <h3 className="settingsLabel">Employees</h3>
                        </Grid>
                        <Grid xs={11}>
                            {!loading && (
                                <>
                                    <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
                                    <DataTable value={employees} footer={footer}>
                                        <Column field="name" header="Name"></Column>
                                        <Column field="email" header="Email"></Column>
                                        <Column field="phoneNumber" header="Phone Number"></Column>
                                        <Column field="location" header="Location"></Column>
                                        <Column field="skills" header="Skills" style={{width: '10%'}}></Column>
                                        <Column field="languages" header="Languages" style={{width: '5%'}}></Column>
                                        <Column field="productTraining" header="Product Training" style={{width: '10%'}}></Column>
                                        <Column field="status" header="Status"></Column>
                                        <Column field="actions" header="Resume" body={actionsBodyTemplate}></Column>
                                        <Column field="edit" header="Edit" body={editBodyTemplate}></Column>
                                    </DataTable>

                                    <Dialog 
                                        visible={addEmployeeDialog} 
                                        breakpoints={{ '960px': '75vw', '641px': '90vw' }} 
                                        header="Employee Details" 
                                        modal 
                                        className="p-fluid add-employee-dialog" 
                                        footer={addEmployeeDialogFooter} 
                                        onHide={hideDialog}
                                    >
                                        <div className="add-employee-dialog-input">
                                            <div className="field">
                                                <label htmlFor="name" className="font-bold">
                                                    Name
                                                </label>
                                                <InputText className='add-employee-dialog-input-text' id="name" value={employee.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus />
                                                {submitted && !employee.name && <small className="p-error">Name is required.</small>}
                                            </div>
                                            <div className="field">
                                                <label htmlFor="email" className="font-bold">
                                                    Email
                                                </label>
                                                <InputText className='add-employee-dialog-input-text' id="email" value={employee.email} onChange={(e) => onInputChange(e, 'email')} required  />
                                            </div>
                                        </div>
                                        <div className="add-employee-dialog-input">
                                            <div className="field">
                                                <label htmlFor="location" className="font-bold">
                                                    Location
                                                </label>
                                                <InputText className='add-employee-dialog-input-text' id="location" value={employee.location} onChange={(e) => onInputChange(e, 'location')} required  />
                                            </div>
                                            <div className="field">
                                                <label htmlFor="phoneNumber" className="font-bold">
                                                    Phone Number
                                                </label>
                                                <InputText className='add-employee-dialog-input-text' id="phoneNumber" value={employee.phoneNumber} onChange={(e) => onInputChange(e, 'phoneNumber')} required  />
                                            </div>
                                        </div>
                                        <div className="add-employee-dialog-input">
                                            <div className="add-employee-dialog-input-file">
                                                <label htmlFor="resume">Upload Resume</label>
                                                <input id="resume" type="file" onChange={handleResumeSelection}/>
                                            </div>
                                        </div>

                                        <div className="admin-add-employee-dropdow-box">
                                            <FloatLabel className='employee-form-select-input'>
                                                <MultiSelect 
                                                    value={selectedLanguages}
                                                    onChange={(e) => setSelectedLanguages(e.value)}
                                                    options={languages} 
                                                    id='language-dropdown'
                                                    optionLabel="language" 
                                                    placeholder="Select Languages" 
                                                    maxSelectedLabels={5}  
                                                    className='w-72'
                                                />
                                                <label htmlFor="language-dropdown">Languages *</label>
                                            </FloatLabel>

                                            <FloatLabel className="w-full">
                                                <Dropdown inputId="dd-city" value={selectedStatus} onChange={(e) => setSelectedStatus(e.value)} options={employeeStatus} optionLabel="status" className="w-full" />
                                                <label htmlFor="dd-city">Employee Status</label>
                                            </FloatLabel>

                                            <FloatLabel className='employee-form-select-input'>
                                                <MultiSelect 
                                                    value={selectedSkills}
                                                    onChange={(e) => setSelectedSkills(e.value)} 
                                                    options={skills} 
                                                    id='skill-dropdown'
                                                    optionLabel="skill" 
                                                    placeholder="Select Skills" 
                                                    maxSelectedLabels={5} 
                                                    className='w-full'
                                                />
                                                <label htmlFor='skill-dropdown'>Skills *</label>
                                            </FloatLabel>

                                            <FloatLabel className='employee-form-select-input test'>
                                                <MultiSelect 
                                                    value={selectedProductTraining}
                                                    onChange={(e) => setSelectedProductTraining(e.value)} 
                                                    options={productTraining} 
                                                    id='products-dropdown'
                                                    optionLabel="skill" 
                                                    placeholder="Select Products Training"
                                                    maxSelectedLabels={5} 
                                                    className='w-full'
                                                />
                                                <label htmlFor='products-dropdown'>Product Training</label>
                                            </FloatLabel>
                                        </div>
                                    </Dialog>

                                    <Dialog 
                                        visible={updateEmployeeDialog} 
                                        breakpoints={{ '960px': '75vw', '641px': '90vw' }} 
                                        header="Update Employee" 
                                        modal 
                                        className="p-fluid add-employee-dialog" 
                                        footer={updateEmployeeDialogFooter} 
                                        onHide={hideUpdateDialog}
                                    >
                                        <div className="add-employee-dialog-input">
                                            <div className="field">
                                                <label htmlFor="name" className="font-bold">
                                                    Name
                                                </label>
                                                <InputText className='add-employee-dialog-input-text' id="name" value={name} onChange={handelName} required autoFocus />
                                                {submitted && !name && <small className="p-error">Name is required.</small>}
                                            </div>
                                            <div className="field">
                                                <label htmlFor="email" className="font-bold">
                                                    Email
                                                </label>
                                                <InputText className='add-employee-dialog-input-text' id="email" value={email} onChange={handleEmail} required  />
                                            </div>
                                        </div>
                                        <div className="add-employee-dialog-input">
                                            <div className="field">
                                                <label htmlFor="location" className="font-bold">
                                                    Location
                                                </label>
                                                <InputText className='add-employee-dialog-input-text' id="location" value={location} onChange={handleLocation} required  />
                                            </div>
                                            <div className="field">
                                                <label htmlFor="phoneNumber" className="font-bold">
                                                    Phone Number
                                                </label>
                                                <InputText className='add-employee-dialog-input-text' id="phoneNumber" value={phone} onChange={handlePhone} required  />
                                            </div>
                                        </div>

                                        <div className="admin-add-employee-dropdow-box">
                                            <FloatLabel className='employee-form-select-input'>
                                                <MultiSelect 
                                                    value={selectedLanguages}
                                                    onChange={(e) => setSelectedLanguages(e.value)}
                                                    options={languages} 
                                                    id='language-dropdown'
                                                    optionLabel="language" 
                                                    placeholder="Select Languages" 
                                                    maxSelectedLabels={5}  
                                                    className='w-72'
                                                />
                                                <label htmlFor="language-dropdown">Languages *</label>
                                            </FloatLabel>

                                            <FloatLabel className="w-full">
                                                <Dropdown inputId="dd-city" value={selectedStatus} onChange={(e) => setSelectedStatus(e.value)} options={employeeStatus} optionLabel="status" className="w-full" />
                                                <label htmlFor="dd-city">Employee Status</label>
                                            </FloatLabel>

                                            <FloatLabel className='employee-form-select-input'>
                                                <MultiSelect 
                                                    value={selectedSkills}
                                                    onChange={(e) => setSelectedSkills(e.value)} 
                                                    options={skills} 
                                                    id='skill-dropdown'
                                                    optionLabel="skill" 
                                                    placeholder="Select Skills" 
                                                    maxSelectedLabels={5} 
                                                    className='w-full'
                                                />
                                                <label htmlFor='skill-dropdown'>Skills *</label>
                                            </FloatLabel>

                                            <FloatLabel className='employee-form-select-input test'>
                                                <MultiSelect 
                                                    value={selectedProductTraining}
                                                    onChange={(e) => setSelectedProductTraining(e.value)} 
                                                    options={productTraining} 
                                                    id='products-dropdown'
                                                    optionLabel="skill" 
                                                    placeholder="Select Products Training"
                                                    maxSelectedLabels={5} 
                                                    className='w-full'
                                                />
                                                <label htmlFor='products-dropdown'>Product Training</label>
                                            </FloatLabel>
                                        </div>
                                    </Dialog>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            }
            {uploadFileModal && (
                <UploadResumeModal closeModal={onCloseModal} employee={selectedEmployee} upload={uploadResume}/>

            )}

            {/* {editEmployeeModal && (
                <EditEmployeeModal employee={selectedEmployee} closeModal={onCloseEditEmployeeModal} update={updateEmployee}/>
            )} */}
        </div>
    )
}