
import AdminComplianceSettingsPage from "../components/admin/AdminComplianceSettingsPage";
import ManagerSidebar from "../components/layout/sidebar/ManagerSidebar";

function AdminComplianceSettings() {
  return (
    <div>
      <ManagerSidebar />
      <AdminComplianceSettingsPage />
    </div>
  );
}

export default AdminComplianceSettings;
