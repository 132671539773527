import "./UserRole.css";
import "./AdminSettings.css";
import "./UpdateRate.css";
import React, { useEffect, useState} from 'react';
import { AdminUserSettingsHeaderLink } from "../layout/header/AdminUserSettingsHeaderLink";
import { Box, Grid } from "@mui/material";
import { get, post, put } from "../api/apiCalls";
import Select from 'react-select'

function UserRole () {
    const [activeUsers, setActiveUsers] = useState([])
    const [roles, setRoles] = useState([])
    let [role, setRole] = useState(2)
    let [selectedUser, setSelectedUser] = useState()
    let user;

    async function getActiveUser(){
        let list = []
        let users = await get(`Users/GetAllUsers?disbled=${false}`)
        users.forEach(e => {
            let model = {
                value: '',
                label: ''
            }
            model.value = e.id
            model.label = `${e.firstName} ${e.lastname}`
            list.push(model)
        });
        setActiveUsers(list)
    }
    async function getRoles(){
        let list = []
        let roles = await get("Users/Roles/GetRoles")
        roles.forEach(e => {
            let model = {
                value: '',
                label: ''
            }
            model.value = e.id
            model.label = `${e.name}`
            list.push(model)
        });
        setRoles(list)
    }

    const handleSelectedRole = (role) => {
        setRole(role)
    }
    const handleSelectUser = async (user) => {
        setSelectedUser(user.value)
        let key = {
            id: user.value
        }
        let selectedUser = await post("Users/GetUserById", key)
        let role = roles.find(x => x.value == selectedUser.role)
        let model = {
            value: role.value,
            label: role.label
        }
        setRole(model)
    }

    const updateUserRole = async () => {
        let keys = {
            userID: selectedUser,
            roleID: role.value
        }
        const response = await put("Users/Roles/UpdateUserRole", keys)
        alert(response)
    }

    useEffect( () => {
        getActiveUser();
        getRoles();
    }, [])
    return(
        <div className="settingsContainer">
            <AdminUserSettingsHeaderLink />
            {
                <Box sx={{ flexGrow: 1 }} className="settingsBody">
                    <Grid container>
                        <Grid xs={10}>
                            <h3 className="settingsLabel">User Role</h3>

                        </Grid>
                        <Grid xs={10} className="user-role-fields">
                            <div className=" dataSelector user-role-dropdown">
                                <Select
                                    className="basic-single user-role-user-selection"
                                    classNamePrefix="select"
                                    placeholder="Users..."
                                    name="color"
                                    value={user}
                                    defaultValue={user}
                                    onChange={handleSelectUser}
                                    options={activeUsers}
                                />
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    placeholder="Roles..."
                                    name="color"
                                    value={role}
                                    defaultValue={role}
                                    onChange={handleSelectedRole}
                                    options={roles}
                                />
                            </div>
                            <div className="user-role-btn-group">
                                <button className="user-role-update-btn" onClick={updateUserRole} disabled={!role || !selectedUser}>Update</button>  
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            }
        </div>
    );
}

export default UserRole;